.style-aside .pro-sidebar-inner {
  background: white;
}

.style-aside .popper-inner {
  background: white !important;
  box-shadow: 0 0 8px rgba(0, 106, 255, 0.16);
  border-radius: 12px !important;
}

.style-aside .pro-menu-item {
  color: black;
}
.style-aside .navbar-icon {
  width: 30px;
  height: 30px;
}
.style-aside .pro-inner-item:hover {
  color: #026aff !important;
}

.style-aside .pro-inner-item:focus {
  color: black !important;
}

.style-aside .pro-item-content a:hover {
  color: #18a0fb !important;
}

.style-aside .pro-item-content a {
  color: black;
}

.style-aside .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background: white;
  padding-left: 50px !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item {
  padding-left: 5px !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}
