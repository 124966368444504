.main {
  position: relative;
}

.form-view {
  box-shadow: none !important;
}
.form-view .main {
  width: 100%;
}
.form-view .drop-down-list-container {
  width: 100% !important;
}
.form-view .search-option {
  width: 100% !important;
}
.form-view .drop-down-header {
  border: 1px solid #b7bdc6;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}
.custom-input {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 #00000008;
  background: white;
  border-radius: 12px;
}
.select-label {
  margin: auto;
  color: #5e5873;
  font-size: 15px;
  padding: 10px 0 10px 15px;
}
.chevron {
  transition: 0.2s ease;
  margin-left: 1rem;
  width: 15px;
  height: 8px;
}
.chevron-rotate {
  transform: rotate(180deg);
}
.drop-down-container {
  margin: 0 auto;
}

.drop-down-header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  padding: 10px 15px 10px 15px;
}

.drop-down-list::-webkit-scrollbar {
  width: 0 !important;
}
.drop-down-list-container {
  box-shadow: 0 5px 25px 0 #0000001a;
  margin-top: 0.5rem;
  width: max-content;
  transition: 0.4s ease;
  position: absolute;
  right: 0;
  border-radius: 6px;
  z-index: 1000;
  background: white;
  overflow: hidden;
}

.drop-down-list {
  min-width: 100%;
  max-height: 10rem;
  overflow-y: scroll;
  width: max-content;
  cursor: pointer;
}
.list-item:hover {
  background: #7367f01f;
  color: #006aff;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #006aff !important;
}
.active {
  background: #7367f01f;
  color: #006aff;
}
.pro-menu-item.active > div.pro-inner-item {
  border-left: 3px solid #006aff;
}

.list-item {
  transition: 0.4s ease;
  font-size: 14px;
  padding: 0.5rem 1rem;
  min-width: 100%;
}

.search-option:focus-visible {
  outline: 0 !important;
}

.options-header {
  position: absolute;
  z-index: 5;
  top: 0;
}
.search-option {
  max-width: 90%;
  font-size: 14px;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ebe9f1;
}

.multiple-header svg {
  margin-bottom: -5px;
}
.multiple-header span {
  padding: 0.3rem 0.4rem 0.4rem 0.4rem;
  margin-right: 0.3rem;
  margin-bottom: 0.2rem;
}
